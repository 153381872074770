.carouselWrapper{
    display:flex;
    align-items: center;
    width:100%;
    flex-wrap: wrap;
    .carousel{
        display:flex;
        justify-content: space-around;
        width: 100%;
        flex-grow: 1;
        .chosenItem{
            font-size: 5em;
            padding:30px;
            font-weight: bolder;
        }
    }
    .pickerButton{
        font-size: 2em;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        flex-grow:1;
    }
}